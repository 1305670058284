const constants = {
    // serverUrl: `http://15.207.147.88:5000/franchisee/`,
    // firebaseSigninUrl: `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDSsD1Iys2QQLQdXpKD13hq1MpA_Wtj6ew`,
    // baseURL:'https://5be6-2401-4900-50a6-e993-6d9e-9141-3541-add0.ngrok-free.app',
    serverUrl: ``,
    firebaseSigninUrl: ``,
    baseURL:'https://jagaranpustikaonline.com'
    // baseURL:'https://1741-2409-40c1-5-afe5-7d3a-43a8-751b-d4d7.ngrok-free.app'
}

export default constants;